import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import Heading from '../../../components/Heading';
import {
  Development,
  Training
} from "../../../Assets/images/index";

const CompanyOverview = () => {
  return (
    <section className="company-overview">
      <Container fluid="xl">
        <div className="overview-item">
          <Row className="flex-column-reverse flex-sm-row">
            <Col lg={5} md={5}>
              <div className="overview-img">
                <img
                  src={Development}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={7} md={7} className="d-flex align-items-center">
              <div className="overview-content">
                <Heading heading="Development" />
                <p>
                  With our efficiently methodical approach we stream line our process in the most productive manner.
                  While planning our principle architecture and Technical decisions, we follow agile methodology by engaging our clients at every step.
                  Employing modern and relavant technologies like Blockchains, NodeJS etc.,
                  in our projects we consistently deliver the solutions iteratively at promised timelines.
                  We work until our service is thoroughly accepted and catered to the business need of our clients.
                  Unbounded by geographies or domains, we are ready to serve you with our vast networks and capabilities.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="overview-item">
          <Row>
            <Col lg={7} md={7} className="d-flex align-items-center">
              <div className="overview-content">
                <Heading heading="Training" />
                <p>
                  Learning is all about understanding the things in the right way. Trainers at Huellas Labs are focussed at educating our aspirants in the most innovative way. Every technology are inculcated with 100 % practical trainings and guidance. The  learners are interested to equip every skill with us once he registers for a single program. Our success resides on the way we deliver every complex concepts in the simplest and understandable terms to our students. The trainer student relationship continues forever where the trainers are ready to help the technophiles at any phase they are stuck with and a mutual learning happens in the process. Thus many turn their dreams into realities by leaning with us.
                </p>
              </div>
            </Col>
            <Col lg={5} md={5}>
              <div className="overview-img">
                <img
                  src={Training}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>

          </Row>
        </div>
      </Container>
    </section>
  );
};

export default CompanyOverview;