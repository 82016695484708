import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import "./Assets/sass/App.scss";
import "./Assets/sass/responsive.scss";
import Navigation from "./components/Header/Navigation";
import Footer from "./components/Footer";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Services from "./Pages/Services/Services";
import ScrollButton from "./components/ScrollButton";
import Preloader from "./components/Preloader";
import Platforms from "./Pages/Platforms/Platforms";
import ScrollToTop from "./ScrollToTop";
import Careers from "./Pages/Careers/Careers";

function App() {
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000);

  }, []);

  const [colorScheme, setColorScheme] = useState('black-theme');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {

    // get current localStorage items themescheme and theme switch button ischecked status
    const currentThemeScheme = localStorage.getItem('theme-scheme');
    const currentIsCheckedStatus = localStorage.getItem('isChecked-status');

    if (currentThemeScheme) {
      setColorScheme(currentThemeScheme);
    }

    // set theme switch button checked status from local storage
    if (currentIsCheckedStatus === 'false') {
      setIsChecked(false);
    }
    if (currentIsCheckedStatus === 'true') {
      setIsChecked(true);
    }
  }, []);

  const handleSwitch = (e) => {
    let theme = colorScheme === 'black-theme' ? "white-theme" : "black-theme";
    let checkedStatus = e.target.checked;
    setIsChecked(checkedStatus);
    setColorScheme(theme);

    // Set themeschme and theme switch button isChecked status
    localStorage.setItem('theme-scheme', theme)
    localStorage.setItem('isChecked-status', checkedStatus)
  }
  return (
    <>
      {loading ?
        <Preloader />
        :
        <BrowserRouter>
          <Navigation handleSwitch={handleSwitch} colorScheme={colorScheme} isChecked={isChecked} />
          <ScrollToTop />
          <Routes>
            <Route path="*" element={<Home colorScheme={colorScheme} />} />
            <Route path="/about-us" element={<About colorScheme={colorScheme} />} />
            <Route path="/service" element={<Services colorScheme={colorScheme} />} />
            <Route path="/portfolios" element={<Platforms colorScheme={colorScheme} />} />
            <Route path="/careers" element={<Careers colorScheme={colorScheme} />} />
            <Route path="/contact-us" element={<Contact colorScheme={colorScheme} />} />
          </Routes>
          <ScrollButton />
          <Footer colorScheme={colorScheme} />
        </BrowserRouter>
      }
    </>
  );
}

export default App;