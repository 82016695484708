import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../components/Heading";
import Slider from "react-slick";
import {
  Solution,
  Planning,
  Agile,
  Technology
} from "../../../Assets/images/index";

const Features = () => {
  const data = [
    {
      id: 1,
      name: "Incredible Solutions",
      img: Solution,
    },
    {
      id: 2,
      name: "Focussed Planning",
      img: Planning,
    },
    {
      id: 3,
      name: "Agile interaction",
      img: Agile,
    },
    {
      id: 4,
      name: "Pioneering Technologies",
      img: Technology,
    },
  ];
  const renderSlides = () =>
    data.map((num) => (
      <Col lg={3} key={num.id}>
        <div className="features-item">
          <div className="features-img">
            <img
              src={num["img"]}
              alt=""
            />
          </div>
          <div className="features-content">
            <h4>{num["name"]}</h4>
          </div>
        </div>
      </Col>
    ));

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="features-section">
      <Container fluid="xxl">
        <Heading
          heading="Discover the key features"
        />
        <Row>
          <Slider {...settings} >
            {renderSlides()}
          </Slider>
        </Row>
      </Container>
    </div>
  )
}

export default Features