import React from 'react'
import preloader from '../Assets/images/preloader.mp4'
export default function Preloader() {
    return (
        <div className='preloader'>
            <video autoPlay controls={false}
                muted
                preload="auto"
                loop>
                <source src={preloader} type="video/mp4" />
            </video>
        </div>
    )
}
