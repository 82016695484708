import React, { useState } from "react";
import Navbar from "./Navbar";
import { routesData } from "./routesData";
import Drawer from "./Drawer";

const Navigation = ({ handleSwitch, colorScheme, isChecked }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={isOpen ? "active" : "head"} style={{ backgroundColor: colorScheme }}>
      <Drawer routes={routesData} isOpen={isOpen} toggleDrawer={toggleDrawer} colorScheme={colorScheme} />
      <Navbar routes={routesData} isOpen={isOpen} toggleDrawer={toggleDrawer} handleSwitch={handleSwitch} colorScheme={colorScheme} isChecked={isChecked} />
    </div>
  );
};

export default Navigation;
