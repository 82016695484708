import React from "react";
import { Container } from "react-bootstrap";
import Heading from "../../../components/Heading";
import Slider from "react-slick";
import QuoteWhite from "../../../Assets/images/quote-white.png"
import QuoteBlack from "../../../Assets/images/quote-black.png"
import { 
  DavidTan,
  Dez,
  David
} from "../../../Assets/images/index";

export default function Clients({ colorScheme }) {
  const data = [
    {
      id: 1,
      name: "Dez",
      post: "Founder of GOATz",
      img: Dez,
      message: "As an NFT Project founder I have had the pleasure of working with Shivam and Huellas Labs on many different occasions. They have handled each project with professionalism, quality, speed and great communication. They deliver innovative, secure, and trusted solutions for even the most complex requests. I could not think of a better team to partner with for web3/blockchain project."
    },
    {
      id: 2,
      name: "David Tan",
      post: "Founder of Collective 168",
      img: DavidTan,
      message: "Shivam is an amazing Solidity developer. He works pretty fast and delivers great work.Feedback from independent reviewers who are experienced in Solidity contracts when I sent them Shivam's code : The code you used is solid Shivam is now the bar which I measure other Solidity devs against"
    },
    {
      id: 3,
      name:"David Pedrini",
      post:"Founder of Flashloans.com",
      img: David,
      message:"Flashloans.com have been working with the team at Huellas labs on several different projects and have found the team to be an amazing support to our business. This has been present in the project scoping where the Huellas labs team had assisted us with project design. All the way through to product development and aftercare processing a fast resolution to any questions we have had. We have also been fortunate to receive forward thinking ideas from the team which has helped fast track our original time estimates. We would not hesitate to recommend."
    }
  ];

  const renderSlides = () =>
    data.map((num) => (
      <div className="feedback-card" key={num.id}>
        <div className="feedback-card-text">
          <img src={colorScheme === "black-theme"? QuoteWhite : QuoteBlack} alt=""/>
          <p>{num.message}</p>
        </div>
        <div className="feedback-card-footer">
          <div className={`client-image border border-2 ${colorScheme === "black-theme" ? "border-white" : "border-dark"}`}>
            <img src={num.img} alt="" />
          </div>
          <div className="client-details">
            <h5 className="title text-center notranslate"> {num.name} </h5>
            <span className="meta notranslate">
              {num.post}
            </span>
          </div>
        </div>
      </div>
    ));

  // customise slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      }
    ]
  };
    
  return (
    <div className="clients section_padding">
      <Container>
        <Heading
          heading="Hear from our Clients"
          top_heading="Clients"
        />

        <Slider {...settings} >
          {renderSlides()}
        </Slider>
      </Container>
    </div>
  );
}