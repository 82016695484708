import React, { useState, useRef } from 'react';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Heading from '../../components/Heading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { CareerImg, Process } from '../../Assets/images/index';
import { Link } from 'react-router-dom';

export default function Careers({ colorScheme }) {
  const form = useRef();
  const [applicantData, setApplicantData] = useState({
    applicant_name: "", applicant_email: "", graduation_year: "", course: "", experience: "", skills: "", contact_number: ""
  })
  const [show, setShow] = useState(false);

  const { applicant_name, applicant_email, graduation_year, course, experience, skills, contact_number } = applicantData;


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleChange = (e) => {
    let { name, value } = e.target;
    setApplicantData({ ...applicantData, [name]: value })
  }
  
  // send email using EmailJS
  const sendEmail = (e) => {
    e.preventDefault();
    
    if (!applicant_name || !applicant_email || !graduation_year || !course || !skills || !experience || !contact_number) {
      toast.error('Please fill out all the required information', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: `${colorScheme === "black-theme" ? "white-toast" : "black-toast"}`,
      });

    } else {
      handleShow()
      emailjs.sendForm('service_97lmgzb', 'template_4t1zlo7', form.current, 'CBYHlaW_LahC-iSYd')
        .then((result) => {

        }, (error) => {
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: `${colorScheme === "black-theme" ? "white-toast" : "black-toast"}`,
          });
        });
      setApplicantData("");
    }
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
  }

  return (
    <React.Fragment>
      <HelmetProvider>
      <Helmet>
        <title>Huellas Labs - Careers</title>
        <meta name="description" content="Careers Page" />
      </Helmet>
      </HelmetProvider>
      <div className={`careers-section ${colorScheme} ${colorScheme === "black-theme" ? "form-black" : "form-white"
        }`}>
        <div className='mx-auto career-wrapper'>
          <Row>
            <div className='d-flex juftify-content-center align-items-center'><img src={CareerImg} alt="" className="mx-auto career-img" /></div>
          </Row>
          <Row>
            <Col>
              <Heading heading="Join Our Team">
              </Heading>
              <div className="form-container mb-5">
                <Form ref={form} onSubmit={sendEmail}>
                  <Form.Group controlId='formBasicName'>
                    <Form.Control type='text' value={applicant_name} name='applicant_name' onChange={handleChange} placeholder='Name' />
                  </Form.Group>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Control type='email' value={applicant_email} name='applicant_email' onChange={handleChange} placeholder='Email' />
                  </Form.Group>
                  <Form.Group controlId='formBasicGaduationYear'>
                    <Form.Control type='number' value={graduation_year >= 0 && graduation_year} name='graduation_year' onChange={handleChange} placeholder='Graduation Year' />
                  </Form.Group>
                  <Form.Group controlId='formBasicCourse'>
                    <Form.Control type='text' value={course} name='course' onChange={handleChange} placeholder='Course' />
                  </Form.Group>
                  {/* <Form.Group controlId='formBasicPosition'>
                    <Form.Select onChange={handleChange} className="mb-3" name='position'>
                      <option value="Front End Developer" className='option-white'>Front End Developer</option>
                      <option value="Backend End Developer" className='option-black'>Backend End Developer</option>
                      <option value="Blockchain Developer" className='option-black'>Blockchain Developer</option>
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group controlId='formBasicSkills'>
                    <Form.Control type='text' name='skills' value={skills} placeholder='Skills Set' onChange={handleChange} />
                  </Form.Group>
                  <Form.Group controlId=''>
                    <Form.Control type='number'  name='experience' value={experience} placeholder='Experience (in Years)' onChange={handleChange} />
                  </Form.Group>
                  <Form.Group controlId='formBasicContactNumber'>
                    <Form.Control type='number' value={contact_number >= 0 && contact_number} name='contact_number' onChange={handleChange} placeholder='Contact Number' />
                  </Form.Group>
                  <button className="main-button" type="submit" value="Send" >
                    <span>Submit</span>
                  </button>
                  <ToastContainer />
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered size="lg" >

        <Modal.Header className={colorScheme}>
          <Modal.Title><Heading heading="Thanks for applying" /> </Modal.Title>
        </Modal.Header>

        <Modal.Body className={colorScheme}>
          <Row>
            <Col lg={4} md={4}>
              <div className="process-img">
                <img src={Process} className='img-fluid' alt="" />
              </div>
            </Col>
            <Col lg={8} md={8}>
              <div className={colorScheme}>
                <h5>Our Team will contact you soon.</h5>
                <p>In the meantime to learn more about the company you can follow us on
                  <a href="https://twitter.com/huellasLabs" target="_blank" rel="noreferrer" className='social-links'> Twitter </a>
                  and
                  <a href="https://www.linkedin.com/company/huellas-labs/" target="_blank" rel="noreferrer" className='social-links'> LinkedIn.</a>
                </p>
              </div>
              <button className="main-button" onClick={handleClose}>
                <Link to="/">Okay</Link>
              </button>
            </Col>
          </Row>
        </Modal.Body>

      </Modal>
    </React.Fragment>
  )
}