import {
  Remote,
  Teamplayer,
  LearningOportunities,
  FlexibleLife,
  WorkAndRelax,
  FutureTechnology
} from "../../Assets/images/index";

export const AboutData = [
  {
    icon: Remote,
    title: "We are Fully Remote",
    description: "We fully support remote work. Because we believe flexibility makes us more productive. Location can be your present place of work would be home or anywhere you are comfortable."
  },
  {
    icon: Teamplayer,
    title: "Great Teamwork",
    description: "We are a team of Technophilic and experienced professionals. Where we work and communicate effectively so that together we can make an idea great."
  },
  {
    icon: LearningOportunities,
    title: "Learning Opportunities",
    description: "Learning is all about understanding the things in the right way. Trainers at Huellas Labs are focussed at educating our aspirants in the most innovative way."
  },
  {
    icon: FlexibleLife,
    title: "Lead your flexible life",
    description: "Work on your own schedules whenever its suits for you and make your life flexible as you want it to be."
  },
  {
    icon: WorkAndRelax,
    title: "Work and relax",
    description: "As we are a remote company you can have your time with your family and motivates you to work hard and relax according to your own time."
  },
  {
    icon: FutureTechnology,
    title: "We already in future",
    description: "At Huellas Labs we develop around highly empowered and passionate developers who are skilled and motivated to provide the best solutions."
  },
]