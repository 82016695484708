import React from 'react'
import Typewriter from 'typewriter-effect';

export default function TypeText() {
    return (
        <Typewriter
            options={{
                strings: ['Secure', 'Defi','NFTs','Contract'],
                autoStart: true,
                loop: true, 
            }}
        />
    )
}
