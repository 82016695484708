import React from "react";
import Banner from "./Banner/Banner";
import Clients from "./Clients/Clients";
import Features from "./Features/Features";
import Walmart from "./Walmart";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CompanyOverview from "./CompanyOverview/CompanyOverview";

const Home = ({ colorScheme }) => {
  return (
    <React.Fragment>
      <div className={colorScheme}>
        <HelmetProvider>
        <Helmet>
          <title>Huellas Labs - Home</title>
          <meta name="description"
            content="Huellas Labs follow the trusted footprints of digital technologies to furnish promising digital solutions for your business ideas." />
        </Helmet>
        </HelmetProvider>
        <Banner />
        <Walmart colorScheme={colorScheme} />
        <CompanyOverview />
        <Features />
        <Clients colorScheme={colorScheme} />
      </div>
    </React.Fragment>
  );
};

export default Home;
