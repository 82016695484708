import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../Assets/images/logo.svg";
import { Container } from "react-bootstrap";
import ThemeToggleButton from "./ThemeToggleButton";

const Navbar = ({ toggleDrawer, routes, isOpen, colorScheme, handleSwitch, isChecked }) => {
  return (
    <header className={colorScheme}>
      <Container fluid="xxl">
        <div className="navtoggle ps-2" onClick={toggleDrawer}>

          {
            isOpen ? < FaTimes /> : <FaBars />
          }
        </div>
        <div className="navbar">
          <Link to="/" className="logo">
            <img src={logo} alt="/" />
          </Link>
          <div className="nav-links">
            <div className="nav-route">
              <ul>
                {routes.map((route) => {
                  return (
                    <li key={route.name} >
                      <NavLink to={route.link}>{route.name}</NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <ThemeToggleButton handleSwitch={handleSwitch} isChecked={isChecked}/>
        </div>
      </Container>
    </header >
  );
};

export default Navbar;
