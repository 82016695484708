import {
  FaInstagram,
  // FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";

export const routesData = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Services",
    link: "/service",
  },
  {
    name: "Careers",
    link: "/careers",
  },
  {
    name: "Contact Us",
    link: "/contact-us",
  },
];
export const routesServiceData = [
  {
    path: "/",
    name: "Home",
  },
  {
    path: "/about-us",
    name: "About Us",
  },
  {
    path: "/careers",
    name: "Careers",
  },
  {
    path: "/contact-us",
    name: "Contact Us",
  },
];
export const serviceLink = [
  // {
  //   path: "/",
  //   name: <FaFacebookF />,
  // },
  {
    path: "https://www.linkedin.com/company/huellas-labs/",
    name: <FaLinkedinIn />,
  },
  {
    path: "https://www.instagram.com/huellaslabs/",
    name: <FaInstagram />,
  },
  {
    path: "https://twitter.com/huellasLabs",
    name: <FaTwitter />,
  },
];
