import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { India, USA } from '../../Assets/images/index';
import Heading from "../../components/Heading";

export default function Contactmy({ colorScheme }) {
  return (
    <div>
      <section className={`contactmy ${colorScheme}`}>
        <Container>
          <Row>
            <Col xl={6}>
              <Row>
                <Col lg={6} md={6}>
                  <div className="contactmy_items">
                    <div className="countryimg">
                      <img src={India} alt="Noida" className='img-fluid' />
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="contactmy_items">
                    <Heading heading="India" />
                    <p>Building No. 84 , Gorakhnath, Gorakhpur, U.P. India</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={6}>
              <Row className="flex-column-reverse flex-sm-row">
                <Col lg={6} md={6}>
                  <div className="contactmy_items">
                    <Heading heading="USA" />
                    <p>3922, Tally Ho Apartments, Torrance, California, U.S.</p>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="contactmy_items">
                    <div className="countryimg">
                      <img src={USA} alt="Noida" className='img-fluid' />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
