import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../components/Heading";
import walmart from '../../Assets/images/banner.gif'
import walmartWhite from '../../Assets/images/banner-white.gif'
import startup_india_logo from '../../Assets/images/startup_india_logo.png'
import {FaUsersCog ,FaRegHandshake ,FaProjectDiagram,FaBusinessTime} from 'react-icons/fa'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'

export default function Walmart({colorScheme}) {
  const [counterOn , setCounterOn] = useState(false);

  return (
    <div className="walmart">
      <Container fluid="xxl">
      <Row>
        <Col md={6} lg={6}>
            <div className="walmart_sec" >
              <Heading
                heading="Why Choose Huellas Labs For Your Next Project?"
              />
              <p style={{marginBottom:"40px"}}>
              We are a TOP Blockchain and Web development company creating beautiful and compelling experiences that impact the world. 
              Ours is a close-knit team of blockchain, frontend, and backend experts proficient and experienced at architecting and developing robust applications using cutting-edge technologies and product strategies.   
              We, at Huellas Labs, take pride in building Successful Apps for TOP clients across the globe.
              So, what are you waiting for? Let us discuss your ideas and our innovation in detail because We have best people team who can assist you in achieving your goals.   
              </p>
              
            </div>
          </Col>
          <Col lg={6} md={6} >
            <div className="startup_logo">
              <img src={startup_india_logo} alt=" startup_india_logo "  />  
            </div>
          </Col>
        </Row>
        <Row style={{display:"flex",justifyContent:"center"}}>
          <Col>
            <div className={`icon_alig ${colorScheme} ${colorScheme === "black-theme" ? "form-black" : "form-white"}`}>
              <Col className="icon_box_align">
                <div className="icon_align">
                  <FaUsersCog className="icon_box"/>
                  <div>
                    <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
                      { counterOn && <CountUp className="countup" decimals={0} start={0} end={100} suffix="+" />}
                    </ScrollTrigger>
                    <p>Happy Client</p>
                  </div>
                </div>
                <div className="icon_align">
                  <FaProjectDiagram className="icon_box"/>
                  <div>
                    <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
                      {counterOn && <CountUp className="countup" decimals={0} start={0} end={50} suffix="+" />}
                    </ScrollTrigger>
                    <p className="p1"> Number Of Projects</p>
                    <p className="p2"> Projects</p>
                  </div>
                </div>
              </Col>
              <Col className="icon_box_align">
                <div className="icon_align">
                  <FaRegHandshake className="icon_box"/>
                  <div>
                    <div className="countup"> 24*7</div>
                    <p>Support</p>
                  </div>
                </div>
                <div className="icon_align">
                  <FaBusinessTime className="icon_box"/>
                  <div>
                    <div className="countup"> On-Time</div>
                    <p> Delivery </p>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md={6} lg={6}>
            <div className="walmart_sec">
              <Heading
                heading="Conquering Spheres"
              />
              <p>
                Open to any challenge and all sectors we readily solve all your problems, with zero boundaries when it comes to domain or genre. Exploring  varied industries successfully has brought us great laurels. Watch your ideas bloom up with us. Catering to your business needs, we persistently ensure you are successful with us. Join us to realize your dreams and visions expeditiously.
              </p>
              {/* <p> We securely deploy your applications using advanced and reliable cryptographic techniques.</p> */}
              {/* <button>
                know More
                <FaAngleRight />
              </button> */}
            </div>
          </Col>
          <Col lg={6} md={6}>
            <div className="walmart_video">

              <img src={colorScheme === "black-theme"? walmart : walmartWhite} className='img-fluid' alt="loading..." />

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}