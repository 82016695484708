import React, { useState } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap';
import Heading from "../../components/Heading";
import {
  Flashloans,
  Goatz,
  Tronsender,
  PolygonSender,
  BinaneSender,
  Collective168,
  MultiSender
} from "../../Assets/images/index";

export default function Platforms({ colorScheme }) {
  const [show, setShow] = useState(false);
  const [sendData, setSendData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true)
    setSendData(data);
  }

  const data = [
    {
      id: 1,
      title: "Flashloans.com",
      desc: "Flash loans are a new uncollateralised loan product in DeFi. Flash loans empower traders and DeFi users by enabling instant borrowing with no collateral required provided that the liquidity is returned to the pool within one transaction block.",
      img: Flashloans,
      url: "https://flashloans.com/"
    },
    {
      id: 2,
      title: "GOATz",
      desc: "GOATz launched in July 2021 and are the 1st ever deﬂationary PFP NFT that enables owners to customize their NFTS through a process called The Forge. GOATz scarcity is always increasing and the art is always being enhanced. Owning a GOAT will unlock the doors to an amazing community and everything we are developing exclusively for GOATz in the different metaverses like The Sandbox and NFT Worlds.",
      img: Goatz,
      url: "https://maisondegoat.com/"
    },
    {
      id: 3,
      title: "Tron Sender",
      desc: "Tron Sender is a tool for bulk sending Tron, TRC10 and TRC20 tokens from a csv file using our Token bulksender tool.",
      img: Tronsender,
      url: "https://tronsender.com/"

    },
    {
      id: 4,
      title: "Polygon Sender",
      desc: "Polygon Sender is a tool for bulk sending tokens from a csv or txt file using our Token batch sending tool.",
      img: PolygonSender,
      url: "https://polygonsender.com"

    },
    {
      id: 5,
      title: "Binance Sender",
      desc: "Binance Sender is a tool for bulk sending BNB and BEP20 tokens from a csv or TXT file.",
      img: BinaneSender,
      url: "http://binancesender.com/"

    },
    {
      id: 6,
      title: "C168",
      desc: "Collective 168 is a media and technology company in the crypto assets space, with a goal to drive value to our collectors in the long run. Our utilities are designed to be sustainable beyond the normal roadmap.",
      img: Collective168,
      url: "https://collective168.com/"

    },
    {
      id: 7,
      title: "Multisend.org",
      desc: "On the other hand, we denounce with righteous indignation and dislike who.",
      img: MultiSender,
      url: "https://multisend.org/",
    },
  ];

  const renderSlides = () =>
    data.map((num) => (
      <Col lg={4} md={6} key={num.id}>
        <div className="platforms-item">
          <div className="platforms-img">
            <img src={num.img} className='img-fluid' alt="" />
          </div>
          <div className="platforms-content">
            <h2>{num.title}</h2>
            <p>{num.desc.length > 140 ?
              `${num.desc.substring(0, 140)}...` : num.desc}</p>
            <div className="platforms-btn">
              <button onClick={event => { handleShow(num) }} className='btn'>Learn more</button>
            </div>
          </div>
        </div>
      </Col>
    ));

  return (
    <section className={`platforms-section ${colorScheme}`}>
      <Container>
        <Heading
          heading="project portfolios"
          top_heading=""
          linkTag="/"
        />
        <Row className='justify-content-center'>
          {renderSlides()}
        </Row>
        <Modal show={show} onHide={handleClose} centered size="lg" >

          <Modal.Header className={colorScheme}>
            <Modal.Title><Heading heading="Project Details" /> </Modal.Title>
          </Modal.Header>

          <Modal.Body className={colorScheme}>
            <Row>
              <Col lg={6} md={6}>
                <div className="platforms-img">
                  <img src={sendData.img} className='img-fluid' alt="" />
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="platforms-txt">

                  <h4>{sendData.title}</h4>
                  <p>{sendData.desc}</p>
                  <button className="main-button" onClick={() => { window.location.href = sendData.url }}>
                    <span>View Project</span>
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          
        </Modal>
      </Container>
    </section>
  )
}
