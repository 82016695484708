import TypeText from "./TypeText";
import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';


export default function Banner() {
  return (
    <section className={`banner clearfix`}>

      <div
        className="item banner-style">
        <div className="banner-caption clearfix h-100">
          <Container fluid="xxl">
            <Row>
              <Col lg={10} md={12} className='mx-auto'>
                <div className="inner-container">
                  <h1 className="banner-heading">Let's Build Together</h1>
                  <p className="banner-description mx-auto">
                    With Huellas Labs, We follow the trusted footprints of Blockchain technology, to furnish promising digital solutions for all your business concerns.
                    Decentralize your data with assured confidentiality. Securely deploy your applications using advanced and reliable cryptographic techniques
                  </p>
                  <TypeText />
                  <div className="banner_buttons">
                    <Link to="/contact-us" className="main-button">
                      <span>Know More</span>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  )
}