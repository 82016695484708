import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import {FaEnvelope} from 'react-icons/fa';
import {FaPhoneAlt} from 'react-icons/fa';
//import {FaWhatsapp} from 'react-icons/fa';
import { Col, Container, Row, Form } from "react-bootstrap";
import Heading from "../../components/Heading";
import Contactmy from './Contactmy';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact({ colorScheme }) {
  const form = useRef();
  const [state, setState] = useState({
    user_name: "", user_email: "", user_sub: "", user_company: "", user_message: ""
  })

  const { user_name, user_email, user_sub, user_company, user_message } = state;

  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value })
  }

  const sendEmail = (e) => {
    e.preventDefault();


    if (!user_name || !user_email || !user_company || !user_message) {
      toast.error('Please enter a valid details', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: `${colorScheme === "black-theme" ? "white-toast" : "black-toast"}`,
      });
    } else {
      emailjs.sendForm('service_97lmgzb', 'template_278735q', form.current, 'CBYHlaW_LahC-iSYd')
        .then((result) => {
          toast.success('Your Details has been Send', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: `${colorScheme === "black-theme" ? "white-toast" : "black-toast"}`,
          });
        }, (error) => {
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: `${colorScheme === "black-theme" ? "white-toast" : "black-toast"}`,
          });
        });
      setState("");
    }
  };
  return (
    <React.Fragment>
      <HelmetProvider>
      <Helmet>
        <title>Huellas Labs - Contact</title>
        <meta name="description" content="Contact Page" />
      </Helmet>
      </HelmetProvider>
      <div className={`contact-section large_padding ${colorScheme} ${colorScheme === "black-theme" ? "form-black" : "form-white"
        }`}>
        <Container>
          <Heading heading="Have a question for us?" />
          <Row>
            <Col lg={6} md={6}>
              <div className="form-container">
                <Form ref={form} onSubmit={sendEmail}>
                  <Form.Group controlId="formBasicName">
                    <Form.Control type="text" value={user_name} name="user_name" onChange={handleChange} placeholder="Name" />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control type="text" value={user_email} name="user_email" onChange={handleChange} placeholder="Email" />
                  </Form.Group>
                  <Form.Group controlId="formBasicSubject">
                    <Form.Control type="text" value={user_sub} name="user_sub" onChange={handleChange} placeholder="Subject (Optional)" />
                  </Form.Group>
                  <Form.Group controlId="formBasicCompany">
                    <Form.Control type="text" value={user_company} name="user_company" onChange={handleChange} placeholder="Company Name" />
                  </Form.Group>
                  <div className="form-group">
                    <textarea className="form-control" value={user_message} name="user_message" onChange={handleChange} placeholder="Message" rows="3" />
                  </div>
                  <button className="main-button" type="submit" value="Send" >
                    <span>Send</span>
                  </button>
                  <ToastContainer />
                </Form>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="contact-content">
                <div className="item1">
                  <h4>Fill in the form</h4>
                  <p>Fill out the contact form and our team will be in touch shortly.</p>
                </div>
                <div className="item2">
                  <h3>You can also connect us at</h3>
                  <h6>Sales Department</h6>
                  <div className="inner_item2"> <FaPhoneAlt/><a href="tel:+919555331917" style={{paddingLeft:"10px"}}>(+91) 955 533 1917</a></div>
                  <h6>Sales Department</h6>
                  <div className="inner_item2"> <FaEnvelope/> <a href="mailto:sales@huellaslabs.com" style={{paddingLeft:"10px"}}>sales@huellaslabs.com</a></div>
                  {/* <h6>Connect on Whatsapp</h6>
                  <div className="inner_item2"> <FaWhatsapp/> <a href="mailto:hello@huellaslabs.com" style={{paddingLeft:"10px"}}> Huellaslabs Private Limited</a></div> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Contactmy colorScheme={colorScheme} />
    </React.Fragment>
  );
}
