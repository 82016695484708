import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  routesServiceData,
  serviceLink
} from "./Header/routesData";
import logo from "../Assets/images/footer-logo.png";

export default function Footer({ colorScheme }) {
  return (
    <footer className={`main-footer ${colorScheme}`} >
      <Container fluid="xxl" className="footer-container pt-5">
        <Row>
          <Col md={6} lg={4} className="footer-column text-left pt-20">
            <div className="logo-widget">
              <div>
                <Link className="logo" to="/">
                  <img
                    src={logo}
                    alt="/"
                    className="img-fluid"
                  />
                </Link>
                <p >At Huellas labs , we promise to provide you the best ever service by deploying promising Technologies. Come on let's be the best team together and scale your business to the next  level.Unravel your success , you are just one step away. Write Us at<a href="mailto:hello@huellaslabs.com" className="highlightText"> hello@huellaslabs.com</a>
                </p>
                
              </div>
            </div>
          </Col>
          <Col md={6} lg={2} className="footer-column d-lg-block pt-20">
            <div className="footer-widget links-widget">
              <h5>COMPANY</h5>
              <ul className="list-link list-unstyled mb-0">
                {routesServiceData.map((route, index) => {
                  return (
                    <li key={index}>
                      <Link to={route.path}>
                        {route.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
          <Col md={6} lg={3} className="footer-column d-lg-block  pt-20">
            <div className="footer-widget news-widget">
              <h5>Services</h5>
              <ul className="list-link list-unstyled mb-0">
                <li><Link to='/service'>Web 2.0 Development</Link></li>
                <li><Link to='/service'>Web 3.0 Development</Link></li>
              </ul>
            </div>
          </Col>
          <Col md={6} lg={3} className="footer-column  pt-20">
            <div className="footer-widget social-box contact-widget">
              <h5>Social</h5>
              {serviceLink.map((route, index) => {
                return (
                  <li key={index}>
                    <a href={route.path} target="_blank" rel="noreferrer">
                      {route.name}
                    </a>
                  </li>
                );
              })}

            </div>
          </Col>
        </Row>
        <div className="copyright">
          Copyright © {(new Date().getFullYear())} <Link to="/" title="Huellaslabs Pvt. Ltd."> Huellaslabs Private Limited. </Link> All Rights Reserved.
        </div>
      </Container>
    </footer>
  );
}
