import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Drawer = ({ isOpen, toggleDrawer, routes, colorScheme }) => {
  return (
    <>
      {isOpen && <div className="Backdrop" onClick={toggleDrawer} />}
      <SDrawer isOpen={isOpen} className={`slideheader ${colorScheme}`}>
        <RightNav>
          <NavRoutes>
            {routes.map((route) => {
              return (
                <li onClick={toggleDrawer} key={route.name}>
                  <Link to={route.link}>{route.name}</Link>
                </li>
              );
            })}
          </NavRoutes>
        </RightNav>
      </SDrawer>
    </>
  );
};

export default Drawer;
const SDrawer = styled.div`
  z-index: 150;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 60%;
  background-color: #000;
  transition: 0.3s ease;

  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
`;

const RightNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  margin-top:80px
`;
const NavRoutes = styled.div``;