import React from 'react';
import sun from '../../Assets/images/sun.png'
import moon from '../../Assets/images/moon.png'

const ThemeToggleButton = ({ handleSwitch, isChecked }) => {
  return (
    <label className='toggle-button'>
      <input type="checkbox" onChange={handleSwitch} checked={isChecked} />
      <span className='slider round'><img src={moon} alt="" /> <img src={sun} alt="" /></span>
    </label>
  );
};

export default ThemeToggleButton;