import {
    Illustrator,
    Photoshop,
    Sketch,
    IOS,
    Android,
    Angular,
    React,
    Node,
    Ethereum,
    Flutter,
    Solidity,
    MetaMask,
    Swift,
    Postgres,
    Mongo,
    Polygon,
    Binance,
    Mythx,
    Avalanche,
    Fantom,
    Xdai,
    Truffle,
    Helios,
    Hardhat,
    Vue
} from "../../Assets/images/index";
export const ServiceData = [
    {
        title: "Blockchain Development",
        desc: "A blockchain is a decentralized digital ledger that saves transactions on thousands of computers around the globe.",
        logos: [
            {
                logo: Binance,
            },
            {
                logo: Fantom
            },
            {
                logo: Ethereum,
            },
            {
                logo: Polygon
            },
            {
                logo: Avalanche
            },
            {
                logo: Xdai
            },
            {
                logo: Helios
            }
        ],
    },
    {
        title: "NFT Development",
        desc: "Ensuring Irrefutable Ownership of Digital Assets",
        logos: [
            {
                logo: Binance,
            },
            {
                logo: Fantom
            },
            {
                logo: Ethereum,
            },
            {
                logo: Polygon
            },
            {
                logo: Avalanche
            },
            {
                logo: Xdai
            },
            {
                logo: Helios
            }
        ],
    },
    {
        title: "Smart Contract Audit",
        desc: "It is an extensive methodical examination and analysis of a smart contract's code that is used to interact with a cryptocurrency or blockchain.",
        logos: [
            {
                logo: Truffle,
            },
            {
                logo: MetaMask,
            },
            {
                logo: Solidity,
            },
            {
                logo: Hardhat,
            },
            {
                logo: Mythx,
            },
        ],
    },
    {
        title: "Web Development",
        desc: "Web development is the work involved in developing a website for the Internet or an intranet.",
        logos: [
            {
                logo: Node,
            },
            {
                logo: Postgres
            },
            {
                logo: Mongo
            },
            {
                logo: Angular,
            },
            {
                logo: React,
            },
            {
                logo: Vue
            }
        ],
    },
    {
        title: "UI UX Development",
        desc: "It is responsible for applying interactive and visual design principles on websites and web applications for a positive and cohesive user experience.",
        logos: [
            {
                logo: Illustrator,
            },
            {
                logo: Angular,
            },
            {
                logo: React,
            },
            {
                logo: Vue
            },
            {
                logo: Sketch,
            },
            {
                logo: Photoshop,
            },
        ],
    },
    {
        title: "Mobile App Development",
        desc: "It is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones",
        logos: [
            {
                logo: Android
            },
            {
                logo: IOS
            },
            {
                logo: Flutter
            },
            {
                logo: Swift
            },
            {
                logo: React
            }
        ],
    },
    {
        title: "Blockchain Training",
        desc: "The courses are designed to help both technical and nontechnical audiences learn the key concepts behind hyperledger and blockchain, making clear the various enterprise applications.",
        logos: [
            {
                logo: Ethereum
            },
            {
                logo: Solidity
            },
            {
                logo: Hardhat
            },
            {
                logo: MetaMask
            }
        ],
    },
    {
        title: "Web Training",
        desc: "Web-based training is often referred to as virtual training or distance learning and uses cloud-based computing tools for access, administration, delivery and analytics.",
        logos: [
            {
                logo: Node,
            },
            {
                logo: Postgres
            },
            {
                logo: Mongo
            },
            {
                logo: Angular,
            },
            {
                logo: React,
            },
            {
                logo: Vue
            }
        ],
    }

];
