import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Heading from "../../components/Heading";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Team } from "../../Assets/images/index";
import { AboutData } from "./AboutData"


export default function About({ colorScheme }) {
  return (
    <React.Fragment>
      <HelmetProvider>
      <Helmet>
        <title>Huellas Labs - About</title>
        <meta name="description"
          content="With Huellas Labs, We follow the trusted footprints of Blockchain technology, to furnish promising digital solutions for all your business concerns. Decentralize your data with assured confidentiality. Securely deploy your applications using advanced and reliable cryptographic techniques" />
      </Helmet>
      </HelmetProvider>
      <section className={`about-section large_padding mt-5 ${colorScheme}`}>
        <Container fluid="xl">
          <div className="about-item">
            <Row>
              <Col lg={7} md={7} className="pt-5">
                <div className="about-content">
                  <Heading heading="Who We Are ?" />
                  <p>
                    We are a team of Technophilic and experienced professionals who adopt every global and web technologies trending across the globe to contribute to your growing business needs.
                    At Huellas Labs we develop around highly empowered and passionate developers who are skilled and motivated to provide best solutions. With prompt delivery of products and services,
                    we are the fastest growing start-up scaling up beyond and above at each step.
                  </p>
                </div>
              </Col>
              <Col lg={5} md={5} >
                <div className="about-img">
                  <img
                    src={Team}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div id="working-section">
            <Heading heading="About working here " />
            <div className="working-here mx-auto d-flex gap-4 flex-wrap justify-content-center">
              {
                AboutData.map((item, index) => {
                  return (
                    <div key={index} className="working-here-wrapper d-flex gap-4 align-items-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <img src={item.icon} alt="" className="fluid work-img" />
                      </div>
                      <div>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
}
