import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { ServiceData } from './ServiceData'
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Heading from '../../components/Heading';

export default function Services({ colorScheme }) {
  return (
    <React.Fragment>
      <HelmetProvider>
      <Helmet>
        <title>Huellas Labs - Service</title>
        <meta name="description"
          content="Huellas, A growing organization that provide solution for blockchain problems like NFT, Token, Auditng etc." />
      </Helmet>
      </HelmetProvider>
      <main className={`service ${colorScheme} ${colorScheme === "black-theme" ? "service-black" : "service-white"
        }`}>
        <section className='expertise-section '>
          <div className='pt-5'>
            <Container>
              <Heading heading="Our Areas of Expertise" />
              <div className='expertise-wrapper'>
                <div className="web2 dot">
                  <h4>Web 2.0 Development</h4>
                  <p>Web 2.0 refers to a paradigm shift in how the internet is used. Over the past 15 to 20 years, the bland webpages of Web 1.0 have been completely replaced by Web 2.0's interactivity, social connectivity, and user-generated content. We are the best in build web 2.0 websites using React, Angular, Vue, Nodejs and MongoDB.</p>
                </div>
                <div className="web3 dot">
                  <h4>Web 3.0 Development</h4>
                  <p>Web 3.0 represents the next iteration or phase of the evolution of the web/internet and potentially could be as disruptive and represent as big a paradigm shift as Web 2.0 did. Web 3.0 is built upon the core concepts of decentralization, openness, and greater user utility. We are best to do this in a better way such as Blockchain, NFT, Web3, Smart Contract, DApps, Defi.</p>
                </div>
              </div>
            </Container>
          </div>
        </section>
        <section className="service-section">
          <Container className='pt-5 pb-3'>
            <Heading heading="We can help you to build something amazing"></Heading>
            <Row className='justify-content-center'>
              {
                ServiceData.map((item, index) => {
                  return (
                    <Col lg={4} md={6} key={index}>
                      <div className="flip-box">
                        <div className="flip-box-inner">
                          <div className="flip-box-front">
                            <h2>{item.title}</h2>
                          </div>
                          <div className="flip-box-back" >
                            <p>{item.desc}</p>
                            <ul className="service-logos">
                              {
                                item.logos && item.logos.map((item, index) => {
                                  return (
                                    <li key={index}><img src={item.logo} alt="" className="img-fluid tech-logo" /></li>
                                  )
                                })
                              }
                            </ul>
                            <Link to='/portfolios' className="main-button">View Project</Link>
                          </div>
                        </div>
                      </div>
                    </Col >
                  )
                })
              }
            </Row >
          </Container >
        </section >
      </main>
    </React.Fragment>
  );
}
